<template>
  <div>
    <profiles></profiles>
  </div>
</template>

<script>
  import Profiles from '../../components/Profiles/Profiles';

  export default {
    name: 'Profiles',
    components: {
      profiles: Profiles
    },
    data() {
      return {

      };
    }
  };
</script>
